@import './styles/fonts/fonts.css';
@import './styles/animations.css';

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

body, html {
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    font-family: "Space Grotesk";
    position: relative;
}

a,
button {
    cursor: pointer;
    -webkit-transition: all .15s linear;
    -moz-transition: all .15s linear;
    -ms-transition: all .15s linear;
    -o-transition: all .15s linear;
    transition: all .15s linear;
    text-decoration: none;
    color: inherit;
    word-break: break-all;
}


h2 {
  display: block;
  font-size: 1em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.transition,
.event-long-title::after {
  -webkit-transition: all .15s linear;
  -moz-transition: all .15s linear;
  -ms-transition: all .15s linear;
  -o-transition: all .15s linear;
  transition: all .115s linear;
}

.transition * {
  -webkit-transition: all .15s linear;
  -moz-transition: all .15s linear;
  -ms-transition: all .15s linear;
  -o-transition: all .15s linear;
  transition: all .115s linear;
}

.block {
  width: 100%;
  min-height: 100vh;
  height: auto;
}

.blue {
  background-color: #395ecf;
}

.lime {
  background-color: #b3ff81;
}

.red {
  background-color: #ff266f;
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  padding: 30px;
  overflow-x: hidden;
}

.red-text {
  color: #ff266f;
}

.blue-text {
  color: #395ecf;
}

.lime-text {
  color: #b3ff81;
}

.faded {
  background-attachment: scroll;
  /*background-image: url('/images/faded.png');*/
  background-position: center;
  background-size: cover;
  min-height: 120vh;
  background-color: #0d107114;
}

.explantion {
  margin: auto;
  margin-top: 0px;
  width: 95%;
  text-align: center;
  font-weight: 400;
  letter-spacing: -.5px;
}

.explantion p {
  margin-bottom: 20px;
  font-size: 18px;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 1.42em;
}

.finesse h2 {
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 1px;
  color: white;
  font-family: "Monument";
  font-weight: 500;
}

.goldmine {
  max-width: 800px;
  color: #395ecf;
  line-height: 1.123em !important;
}

.finesse {
  text-align: center;
  font-weight: 500;
  margin: auto;
}

.finesse p {
  font-size: 40px;
  line-height: 1em;
  letter-spacing: -2px;
}

.finesse .lime-text {
  max-width: 650px;
}

.info-slide {
  min-height: 65vh;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-flow: row wrap;
}
.small {
  font-size: 30px !important;
}


.ad-info, .ad-image {
  width: 100%;
  text-align: left;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  min-height: 100%;

}

.ad-info {
  padding: 30px;
}

.ad-image {
  display: block;
}

.ad-info h1 {
  font-size: 60px;
  margin-bottom: 50px;
  max-width: 420px;
  text-align: left;
  align-self: normal;
  font-weight: 500;
  line-height: 1.09em;
  letter-spacing: -2px;
}

.ad-info p {
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 30px;
  letter-spacing: -.5px;
}

.ad-image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.red-info {
  color: white;
}

.next-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: white;
}

img.down-arrow {
  margin-bottom: 7px;
  width: 10px;
}

.section-next-text {
  font-size: 12px;
  font-family: "Monument";
  font-weight: 500;
}

img.clouds {
  position: absolute;
  z-index: 0;
  opacity: .9;
  width: 120%;
  height: 100%;
  object-fit: cover;
}

.header {
  position: fixed;
  height: 80px;
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  color: white;
}

.header img {
  height: 100%;
  width: 150px;
  object-fit: contain;
}

.header-cta {
  background: #fe266f00;
  display: block;
  padding: 10px 34px;
  border-radius: 4px;
  font-family: inherit;
  color: white;
  font-family: "Monument";
  background: #fe266f;
  border-radius: 60px;
  border: solid 3px #cccccc;
}

.goldmine a {
  color: #ff266f;
  padding: 0px 5px;
}

.goldmine a:hover {
  color: #395ecf;
}

.header-cta:hover {
  border-color: #395ecf;
  background-color: #395ecf;
}
.block.block-small {
  min-height: 400px;
  height: auto;
}

.block-small .finesse {
  top: unset;
}
.block-small .goldmine {
  font-size: 30px;
}

footer.footer {
  padding: 14px 30px;
  color: white;
  border-top: solid 10px #00000026;
  background: #292929;
  font-weight: 500;
  background-size: cover;
}

.ReactTypeformEmbed {
  padding: 10px;
  right: 0px;
  margin: auto;
  max-width: 98%;
}


.block.section.white {
  background: linear-gradient(to bottom, #395ecf, #fe266f);
  /* border-color: white; */
}

.dots {
  background-attachment: fixed;
  width: 100%;
  display: block;
  height: 50vh;
  position: absolute;
  bottom: 0px;
  background-position: 50%;
  background-size: 16px 10px;
  z-index: -1;
  opacity: .6;
}

.welcome {
  color: black;
  font-weight: 600;
  font-size: 20px;
}

.header-name {
  font-weight: 600;
  color: #fe266f;
}


.signed-in-main {
  margin-top: auto;
  width: 100%;
}


.balance {
  padding: 50px;
  text-align: center;
}

.a-balance {
  font-weight: 300;
  font-size: 11px;
  font-family: "Monument";
  text-transform: uppercase;
}

.balance-number {
    margin-top: 15px;
    font-size: 50px;
    font-weight: 600;
    color: #395ecf;
    border-bottom: 0px solid #b3ff81;
    box-shadow: 0px -31px 0px #b3ff81 inset;
    width: auto;
    display: inline-block;
    line-height: 58px;
}


.signed-in-buttons {
  padding: 25px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.signed-in-buttons button {
  padding: 10px 30px;
  margin: 5px 10px;
  min-width: 300px;
  background: #3761d514;
  font-family: 'Monument';
  color: #3b60d0;
  font-weight: 300;
  font-size: 15px;
}

.signed-in-buttons button:hover   {
  background-color: #385ecf52;;
}

#cash-out-button {
  background: #b2ff8038;
}

#cash-out-button:hover {
  background: #b3ff81;
  color: #3761d5;
}

.sign-switch {
  font-weight: 500;
  color: #fe266f;
  padding: 5px;
}

.sign-switch:hover {
  color: #395ecf;
  background: #b3ff81;
}

.cloudpay-button {
  width: 100%;
  padding: 20px;
  background: #395ecf;
  border-radius: 4px;
    color: white;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 20px;
}

.cloudpay-button:hover {
  background-color: #fe266f;
}


.vendor-shopper-label {
  letter-spacing: 2px;
  font-size: 10px;
  font-weight: 600;
  color: #385ecf;
  text-transform: uppercase;
  padding: 0px;
}

.signup {
  max-width: 540px;
  width: 95%;
  margin: auto;
  padding: 30px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background: white;
  box-shadow: 1px 1px #00000008;
}

.sign-title {
  font-weight: 700;
  color: #fe266f;
  font-size: 42px;
  margin-bottom: 50px;
}

.cloudpay-input {
  width: 100%;
  border-radius: 4px;
  padding: 8px 10px;
  border: solid 1px #f6f6fc;
  font-size: 20px;
  font-family: 'Space Grotesk';
  margin-bottom: 8px;
  background: #f6f6fc;
  color: black;
  font-weight: 500;
}


.signup .label {
  align-self: flex-start;
  /* padding-left: 12px; */
  margin-top: 5px;
  font-size: 11px;
  opacity: .5;
  font-weight: 400;
}

.cash-out-box {
  align-self: center;
  margin: auto;
  background: white;
  padding: 50px;
  max-width: 600px;
}

.cashing-out .balance-number {
  box-shadow: 0px -31px 0px #385ecf1f inset;
}

.cash-out-to {
  margin-top: 20px;
}

.cash-out-label {
  font-family: 'Monument';
  font-weight: 300;
  font-size: 10px;
  color: #383838;
  margin-bottom: 3px;
}

.account-number {
  font-size: 14px;
  margin: 6px;
  color: #385ecf;
  background: #f6f8ff;
  border-radius: 4px;
  padding: 5px 20px;
  display: inline-block;
}

.cashing-out .cash-out-button, .edit-save, .dashboard-button {
  padding: 10px 30px;
  margin-top: 25px;
  min-width: 300px;
  background: linear-gradient(40deg, rgb(56 94 207 / 10%), rgb(179 255 129 / 23%));
  font-family: 'Monument';
  color: #385ecf;
  font-weight: 300;
  font-size: 15px;
  width: 100%;
  border-radius: 5px;
}

.dashboard-button {
  font-family: "Space Grotesk";
  background: linear-gradient(90deg, rgba(255, 38, 111, 0.05) 0%, rgba(54, 97, 210, 0.05) 100%);
  border-radius: 6.84615px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: #FF266F;
}

.balance-info {
  margin-top: 20px;
  margin-bottom: 10px;
}


.exit-x {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 40px;
  font-weight: 100;
  color: #b7c6ef;
  cursor: pointer;
  padding: 5px;
}

.exit-x:hover {
  transform: scale(1.03);
}


.edit-account {
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  color: #fe266f;
}

.sign-out {
  margin-left: 30px;
  background: #fe266f12;
  color: white;
  padding: 5px 15px;
  font-weight: 500;
  cursor: pointer;
  color: #fe266f;
  font-family: inherit;
}

.sign-out:hover {
  background-color: #fe266f36;
}

.edit-bank {
  width: 100%;
  /* padding: 20px; */
  overflow-y: scroll;
  display: flex;
    flex-flow: column;
}

.edit-header {
  padding: 40px 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #f0f3fc;
  color: #395ecf;
}

.edit-title {
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
}

.edit-subtitle {
  padding: 10px;
  font-size: 15px;
  text-align: center;
}

.edit-form {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
}

.edit-form .row {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.row-col {
  margin-left: 10px;
  margin-right: 10px;
  flex: 1 1 auto;
}

.edit-info-title {
  width: 100%;
  /* flex: 1 1 auto; */
  font-size: 16px;
  text-align: center;
}

.edit-save {
  margin: auto;
  width: 100%;
  align-self: center;
  background: #3761d514;
}


.dashboard-info {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 700px;
  flex: 1 1 auto;
  min-height: 100vh;
  padding: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}

.right {
  margin-left: auto;
  width: 50%;
}

.left {
  margin-right: auto;
  width: 50%;
}

.dashboard-info .cloudpay-logo-blue {
  width: 140px;
  margin: auto;
  align-self: center;
  margin-bottom: 30px;
  margin-top: 0px;
}


.money-made {
  padding: 20px;
  text-align: center;
}

.money-amount {
  font-size: 50px;
}

.money-sub {
  font-size: 13px;
  line-height: 22px;
/* identical to box height */
  text-align: center;
  text-transform: lowercase;
  color: #999999;
}


.dashboard-data {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1 1 auto;
  justify-content: space-between;
}

.dashboard-data {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  flex: 1 1 auto;
  justify-content: space-between;
}

.dashboard-name {
  font-weight: 800;
  font-size: 14.5385px;
  margin-right: 8px;
}

.dashboard-company {
  font-size: 15.54px;
  line-height: 28px;
  text-align: center;
  text-transform: capitalize;
  color: #3661D2;
  font-weight: 400;
  margin-left: 8px;
}

.dashboard-bottom {
  margin-top: auto;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}


.info-rows {
  width: 100%;
  padding: 20px;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
}
.dash-row {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  padding: 10px 5px;
  border-bottom: 1px solid #E8E8E8;
}

.row-title {
    font-weight: 500;
    font-size: 16px;
}

.row-info {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: capitalize;
}


.purchases-panel {
  height: 100vh;
  width: 50%;
  background: #F2F2F2;
  overflow-y: scroll;
  padding-bottom: 20px;
  flex: 1 1 auto;
}

.dashboard {
  width: 100%;
  width: 100vw;
  display: flex;
}


.purchases-title {
  background: #3661D2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  padding: 15px;
  text-align: center;
  color: white;
  font-size: 17.5385px;
  text-transform: uppercase;
  font-weight: 500;
  position: fixed;
  width: auto;
  z-index: 10;
  top: 0px;
  left: 0px;
}
.dash-purchases-list {
  padding-top: 80px;
  padding-left: 30px;
  padding-right: 30px;
}

.purchase-buyer {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -1px;
  margin-right: auto;
}

.purchase-date {
  margin-left: auto;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #3661D2;
}

.dash-purcahse {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 2px;
  padding: 15px;
  display: flex;
  flex-flow: column;
}

.buyer-email {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #3661D2;
  margin-top: 5px;
}

.purchase-price {
  background: rgba(54, 97, 210, 0.25);
  border-radius: 300px;
  margin-left: auto;
  text-align: center;
  display: inline-block;
  padding: 1px 19px;
  font-weight: 800;
  font-size: 15px;
  
/* identical to box height */
  color: #3661D2;
  min-width: 80px;
}

.see-more, .see-less {
  font-weight: 800;
  font-size: 11px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #FF266F;
  margin-top: 6px;
  cursor: pointer;
}

.purchase-item {
  width: 100%;
  background: #8080800d;
  display: flex;
  padding: 2px 5px;
  align-items: center;
  justify-content: space-around;
}

.purchase-item-name {
  font-size: 13px;
}

.purchase-item-price {
  font-size: 15px;
  background: #b3ff81;
  font-weight: 500;
}

@media screen and (min-width: 800px) {
  .finesse p {
    font-size: 60px;
}

.finesse {
  top: 50px;
}

.block.block-small {
  margin-top: 80px;
}

.block-small .goldmine {
  font-size: 52px;
  padding: 20px;
}

.ad-image, .ad-info {
  width: 50%;
}

.ad-info {
  padding: 100px 50px;
}

.faded {
  background-attachment: fixed;
}

}