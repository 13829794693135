

/* animations */

/**
 * ----------------------------------------
 * animation scale-down-hor-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}


/**
 * ----------------------------------------
 * animation scale-down-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}



/* ----------------------------------------------
 * Generated by Animista on 2019-4-6 17:18:45
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2019-4-6 18:8:51
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-hor-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}



@keyframes animatedBackground {
    0% { background-position: top center; }
    100% { background-position: -100px center; }
}
@-moz-keyframes animatedBackground {
    0% { background-position: top center; }
    100% { background-position: -100px 0; }
}
@-webkit-keyframes animatedBackground {
    0% { background-position: top center;}
    100% { background-position: -100px 0; }
}
@-ms-keyframes animatedBackground {
    0% { background-position: top center; }
    100% { background-position: -100px center; }
}
@-o-keyframes animatedBackground {
    0% { background-position: top center; }
    100% { background-position: -100px center; }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}



@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}



.animate-area { 
    background-position: 0px 0px;
    animation: animatedBackground 2s ease infinite alternate forwards;
    -moz-animation: animatedBackground 2s ease infinite alternate forwards;
    -webkit-animation: animatedBackground 2s ease infinite alternate forwards;
    -ms-animation: animatedBackground 2s ease infinite alternate forwards;
    -o-animation: animatedBackground 2s ease infinite alternate forwards;
}



.scale-up-hor-center {
  -webkit-animation: scale-up-hor-center 0.4s ease-out both;
          animation: scale-up-hor-center 0.4s ease-out both;
}


