@font-face {
font-family: 'Brandon Grotesque';
src: url('brandon_reg.ttf') format('truetype');
font-weight: 300;
font-style: normal;
}
@font-face {
font-family: 'Brandon Grotesque';
src: url('brandon_med.ttf') format('truetype');
font-weight: 500;
font-style: normal;
 }
@font-face {
font-family: 'Brandon Grotesque';
src: url('brandon_bld.ttf') format('truetype');
font-weight: 700;
font-style: normal;
}


@font-face {
	font-family: 'Space Grotesk';
	src: url('SpaceGrotesk-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

	@font-face {
		font-family: 'Space Grotesk';
		src: url('SpaceGrotesk-Regular.ttf') format('truetype');
		font-weight: 400;
		font-style: normal;
	}
		
	
@font-face {
	font-family: 'Space Grotesk';
	src: url('SpaceGrotesk-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	 }
@font-face {
	font-family: 'Space Grotesk';
	src: url('SpaceGrotesk-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Monument';
	src: url('MonumentExtended-Ultralight.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Monument';
	src: url('MonumentExtended-Light.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Monument';
	src: url('MonumentExtended-Regular.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}


@font-face {
	font-family: 'Monument';
	src: url('MonumentExtended-Bold.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}


@font-face {
	font-family: 'Monument';
	src: url('MonumentExtended-Ultrabold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
